import * as sentry from '@sentry/angular';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@ls-front/env';

import { AppModule } from './app/app.module';

/**
 * Configure Sentry
 * Configuration should happen as early as possible in your application's lifecycle.
 *
 * Once this is done, Sentry's Angular SDK captures all unhandled exceptions and transactions.
 * @see https://docs.sentry.io/platforms/javascript/guides/angular/#configure
 */
sentry.init({
    dsn: 'https://d95720d7f10746d2beebc386c61bae0f@o355624.ingest.us.sentry.io/2732623',
    integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        sentry.browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        sentry.replayIntegration({
            /**
             * Additional SDK configuration goes in here, for example
             * @see https://docs.sentry.io/platforms/javascript/guides/angular/session-replay/#set-up
             */
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
