import { Component, Renderer2 } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

import { ThemeService } from './core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    standalone: false,
})
export class AppComponent {
    public asyncLoadCount = 0;
    private currentThemeName = '';

    constructor(private theme: ThemeService, private renderer: Renderer2, route: Router) {
        const isFinalRouteEvent: (event: Event) => boolean
            = event => event instanceof NavigationError || event instanceof NavigationEnd || event instanceof NavigationCancel;

        route.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.asyncLoadCount++;
            } else if (isFinalRouteEvent(event) && this.asyncLoadCount > 0) {
                this.asyncLoadCount--;
            }
        });

        this.updateBodyClass(this.theme.get());
        this.theme.changed.subscribe(themeName => this.updateBodyClass(themeName));
    }

    private updateBodyClass(themeName: string): void {
        this.renderer.removeClass(document.body, 'theme-' + this.currentThemeName);
        this.currentThemeName = themeName;
        this.renderer.addClass(document.body, 'theme-' + this.currentThemeName);
    }
}
